<template>
        <section class="blockElement space bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
                            <ol class="breadcrumb mb-0">
                                <li class="breadcrumb-item f-12"><router-link :to="{name:'calculator'}">{{$t('calculator.text19')}}</router-link></li>
                                <li class="breadcrumb-item f-12 active" aria-current="page" v-if="$route.name=='pipCalculator'">{{$t('calculator.text20')}}</li>
                                <li class="breadcrumb-item f-12 active" aria-current="page" v-else-if="$route.name=='marginCalculator'">{{$t('calculator.text21')}}</li>
                                <li class="breadcrumb-item f-12 active" aria-current="page" v-else-if="$route.name=='profitCalculator'">{{$t('calculator.text22')}}</li>
                                <li class="breadcrumb-item f-12 active" aria-current="page" v-else-if="$route.name=='currencyCalculator'">{{$t('calculator.text23')}}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement pt-0 bg-white forexTool-page">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-12 col-lg-10">
                        <ul id="tabs-nav" class="zuluTabs large d-flex large align-items-center mb-4">
                            <li :class="[{'active':$route.name=='pipCalculator'}]"><router-link :to="{name : 'pipCalculator'}" class="medium" href="javascript:void(0)">{{$t('calculator.text4')}}</router-link></li>
                            <li :class="[{'active':$route.name=='marginCalculator'}]"><router-link :to="{name : 'marginCalculator'}" class="medium" href="javascript:void(0)">{{$t('calculator.text6')}}</router-link></li>
                            <li :class="[{'active':$route.name=='profitCalculator'}]"><router-link :to="{name : 'profitCalculator'}" class="medium" href="javascript:void(0)">{{$t('calculator.text8')}}</router-link></li>
                            <li :class="[{'active':$route.name=='currencyCalculator'}]"><router-link :to="{name : 'currencyCalculator'}" class="medium" href="javascript:void(0)">{{$t('calculator.text10')}}</router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
</template>