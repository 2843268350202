<template>
    <div id="tab3" class="tab-content">
        <Calculator/>
        <section class="blockElement space pt-0 bg-white forexTool-page">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-12 col-lg-10">
                        <div class="bg-white boxedShadow">
                            <div class="topRadius">
                                <h1 class="f-30 semibold line-1">{{$t('calculator.text8')}}</h1>
                                <p>{{$t('calculator.text61')}}</p>
                                <Form class="allCalculator mt-md-5" @submit="profitsubmit">
                                    <div class="row">
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label>{{$t('calculator.text25')}}</label>
                                               <!--  <input type="text" class="form-control" v-model="searchCurr" @input="Searching($event)" placeholder="Search for CFD, stocks, commodities..." v-if="form.currencyId == ''" /> -->
                                                 <Field
                                                    type="text"
                                                    class="form-control"
                                                    name="currency"
                                                    autofill="off"
                                                    v-model="searchCurr"
                                                    rules="required"
                                                    placeholder="Search for CFD, stocks, commodities..." 
                                                    @input="Searching($event)" 
                                                    v-if="form.currencyId == ''"
                                                />
                                               <Field
                                                    type="text"
                                                    class="form-control"
                                                    name="currency_search"
                                                    autofill="off"
                                                    v-model="form.currencyId"
                                                    rules="required"
                                                    placeholder="" 
                                                    v-else
                                                />
                                                <div class="dropdown_menu_animated p-0 globalSearch mh-340 w-100 show" v-if="(searchCurr) && Object.keys(store.assetSearchList).length">
                                                    <ul class="globaListSearch">
                                                        <li v-for="item,index in Object.values(store.assetSearchList)" :key="index">
                                                            <a href="javascript:void(0)" class="d-flex align-items-center justify-content-between" @click="clearList(item)">
                                                                <span class="d-flex align-items-center">
                                                                    <span class="me-3">
                                                                        <v-lazy-image width="24" height="24" class="euCurrency" :src="static_vars.marketImageSURL+item.symbol.toUpperCase().replace(/\//g, '')+'.svg'" :alt="item.symbol.toString()" :title="item.symbol.toString()" />
                                                                    </span>
                                                                    <span class="me-2 f-15 menutext minWidthName medium">{{item.symbol}}</span>
                                                                </span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <ErrorMessage class="text-danger" name="currency" />
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label>{{$t('calculator.text26')}}</label>
                                                <Field
                                                type="number"
                                                class="form-control"
                                                name="lots"
                                                autofill="off"
                                                v-model="form.lots"
                                                rules="required"
                                                />
                                                <ErrorMessage class="text-danger" name="lots" />
                                            </div>
                                        </div>
                                        <!-- <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label>{{$t('calculator.text27')}}</label>
                                                <Field
                                                type="number"
                                                class="form-control"
                                                name="pips"
                                                autofill="off"
                                                v-model="form.pips"
                                                rules="required"
                                                />
                                                <ErrorMessage class="text-danger" name="pips" />
                                            </div>
                                        </div> -->
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label>{{$t('calculator.text28')}}</label>
                                                <Field
                                                  class="form-select"
                                                  as="select"
                                                  name="base currency"
                                                  rules="required"
                                                  placeholder=""
                                                  v-model="form.baseCurrency"
                                                  v-if="store.calulatorBaseCurrencies.length"
                                                >
                                                <option value="">{{$t('calculator.text29')}}</option>
                                                <option v-for="item in store.calulatorBaseCurrencies" :key="item" :value="item">{{item}}</option>
                                                </Field>
                                                <ErrorMessage name="base currency" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label>{{$t('calculator.text62')}}</label>
                                                <Field
                                                type="number"
                                                class="form-control"
                                                name="open price"
                                                autofill="off"
                                                v-model="form.openPrice"
                                                rules="required"
                                                />
                                                <ErrorMessage class="text-danger" name="open price" />
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label>{{$t('calculator.text63')}}</label>
                                                <Field
                                                type="number"
                                                class="form-control"
                                                name="close price"
                                                autofill="off"
                                                v-model="form.closePrice"
                                                rules="required"
                                                />
                                                <ErrorMessage class="text-danger" name="close price" />
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label>{{$t('calculator.text30')}}</label>
                                                <Field
                                                  class="form-select"
                                                  as="select"
                                                  name="mini lots"
                                                  rules="required"
                                                  placeholder=""
                                                  v-model="form.stdLots"
                                                >
                                                <option v-for="item,key in miniLotsJson" :key="item" :value="key">{{key}}</option>
                                                </Field>
                                                <ErrorMessage name="mini lots" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-12 text-center mt-3">
                                            <button type="submit" class="button fillSpiner fillBtn zulu_btn px-md-5">
                                                <Spinner class="buttonSpiner" v-if="loadingCalculator"></Spinner>{{$t('calculator.text31')}}
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                            <div class="getResult bottomRadius">
                                <ul class="resultList d-flex align-items-center flex-wrap mb-0">
                                    <li>
                                        <p class="f-30 mb-2">{{response.rate || '0.00'}}</p>
                                        <p class="mb-0 f-13">{{$t('calculator.text32')}}</p>
                                    </li>
                                    <li>
                                        <p class="f-30 mb-2">{{response.value || 0}}</p>
                                        <p class="mb-0 f-13">{{$t('calculator.text33')}}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space faqs automated6">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2 class="mb-md-4 pb-4">{{$t('calculator.text12')}}</h2>
                    </div>
                    <div class="col-12">
                        <div class="accordion accordion-flush" id="accordionFlushExample2">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingOne">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    {{$t('calculator.text64')}}
                                    </button>
                                </h2>
                                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample2">
                                    <div class="accordion-body"> {{$t('calculator.text65')}}</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                     {{$t('calculator.text66')}}
                                    </button>
                                </h2>
                                <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample2">
                                    <div class="accordion-body">{{$t('calculator.text67')}}</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                    {{$t('calculator.text68')}}
                                    </button>
                                </h2>
                                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample2">
                                    <div class="accordion-body">{{$t('calculator.text69')}}</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingFour">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                   {{$t('calculator.text70')}}
                                    </button>
                                </h2>
                                <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample2">
                                    <div class="accordion-body">{{$t('calculator.text71')}}</div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import { myStore } from "@/store/pinia-store.ts";
    import { Form, Field, ErrorMessage } from "vee-validate";
    import Calculator from "@/views/markets/calculator/calculator"
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        mounted(){
            this.currencylist = this.static_vars.currencyList
        },
        components: {
            Form,
            Field,
            ErrorMessage,
            Calculator
        },
        data() {
            return {
                loadingCalculator: false,
                currencylist:{},
                response: {},
                searchCurr : '',
                form: {
                    currencyId: '',
                    lots: '1',
                    currencyAccountId: '',
                    // pips: '1',
                    stdLots: 'STANDARD',
                    openPrice : '',
                    closePrice : '',
                    baseCurrency : ''
                },
                miniLotsJson : {
                    'STANDARD' : 'Standard',
                    'MINI' : 'Mini',
                    'MICRO' : 'Micro',
                },
            }
        },
        methods:{
            Searching(e) {
                if (e.target.value) {
                    this.searchdata = e.target.value;
                    this.store.callassetSearchList({ searchText: this.searchdata }, false);
                } else {
                    this.searchdata = "";
                }
            },
            clearList(item){
                this.form.currencyId = item.symbol;
                this.searchCurr = ''
                this.store.$patch({'assetSearchList' : {}})
                if(Object.keys(this.store.allPrices).length && this.form.currencyId in this.store.allPrices){
                    this.form.openPrice = this.store.allPrices[this.form.currencyId].buyPrice
                    this.form.closePrice = this.store.allPrices[this.form.currencyId].sellPrice
                }
            },
            profitsubmit(){
                this.store.profitcalculate(this.form, true, this).then(response => {
                    this.loading = false
                    this.response = response
                });
            }
        },
        computed: {
            isActive() {
                return false
            }
        },
    }
</script>